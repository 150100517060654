@font-face {
  font-family: "Corben-Bold";
  src: url(./assets/fonts/Corben-Bold.ttf) format("truetype");
}

.brandText{
  font-family: "Corben-Bold";
}

.navLogo{
  max-height: 100px;
}

.pageBody{
  margin-top:20px;
  text-align: center;
}